
.CalendarDay__selected:hover {
  background: orange;
  color: white;
}

.CalendarDay__selected_span{
  border-radius: 0px !important;
  box-sizing: border-box !important;
  cursor: pointer !important;
  text-align: center !important;
  color: rgb(34, 34, 34) !important;
  padding: 0px !important;
  background: #f2f5f5 !important;
}

.CalendarDay__selected_span:active, .CalendarDay__selected_span:hover{
  background: #e4e7e7 !important;
}

.CalendarDay__selected {
  background: #69C05B !important;
  border-radius: 50% !important;
  transition: 0.2s all;
}


.CalendarDay__selected:active{
  background: red !important;
}

.CalendarDay__selected:hover{
  background: #5bab4f !important;
}

.CalendarDay__default:hover {
  background: #5dab50 !important;
  color: white !important;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover{
  background: #e4e7e7 !important;
  color: rgb(34, 34, 34) !important;
}

.CalendarDay {
  border-radius: 50% !important;
  text-align: center;
  vertical-align: middle;
  text-align: center;
  gap: 5px;
}



.DateInput {
  width: 48% !important;
  max-width: 600px !important;
  margin-top: 15px;
}

@media only screen and (min-width: 200px) {
  .DateInput {
    width: 100% !important;
  }
}

/* Small (sm) */
@media only screen and (min-width: 576px) {
  .DateInput {
    width: 100% !important;
  }
}

/* Medium (md) */
@media only screen and (min-width: 768px) {
  .DateInput {
    width: 48% !important;
  }
}

/* Large (lg) */
@media only screen and (min-width: 992px) {
  .DateInput {
    width: 48% !important;
  }
}

/* Extra large (xl) */
@media only screen and (min-width: 1200px) {
  .DateInput {
    width: 48% !important;
  }
}

/* Extra extra large (xxl) */
@media only screen and (min-width: 1400px) {
  .DateInput {
    width: 48% !important;
  }
}

