@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,700;1,100&display=swap');

.border-dashad {
  border-bottom: 2px dashed rgb(208, 208, 208);
  border-top: 0;
}

@media print {
  .marker::before {
    content: url('../../img/location_pin.png');
  }

  .marker {
    background-image: none;
  }
}
.nowrap {
  white-space: nowrap !important;
}
.mapboxgl-canvas-container {

  height: 100vw;

}
.filter-list{
  margin-top:15px;
  display: flex;
  gap: 32px;
  list-style: none;
  margin-left: 0;
  padding: 0;
  align-content: center;
    justify-content: center;
    align-items: center;
}
.painel-prospecacao span{
  font-weight:600;
}
.chat{
  height: 306px;
  overflow: scroll;
}
.painel-prospecacao{
  width: 435px;
  height: auto;
  position: absolute;
  left: 0px;
  bottom: 0px;
  background-color: #ffff;
  padding: 14px;
  max-height: 381px;
  overflow: auto;
}


.tooltip-prospecacao{
  position: absolute;
  left: 910px;
  top: 306px;
  background: white;
  padding: 14px 15px 3px 15px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;

}
.tooltip-prospecacao p {
  padding: 0px;
  margin-bottom: 10px;
}
.tooltip-prospecacao span {
  font-weight:600;
}
#chart-wrap {
  max-width: 650px;
  position: relative;
  margin: 35px auto;
}

#chart {
  padding-bottom: 30px;
  /* max-width: 650px; */
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
}

#chart .legend {
  /* max-width: 650px; */
  /* left: 80px; */
  /* bottom: 20px; */
  /* position: absolute; */
  /* text-align: center; */
  /* margin: 0 auto; */
  display: contents;
}
/* 
.marker {
  background-image: url('mapbox-icon.png');
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;

} */


#vertical-tabs-example .nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
  padding: 15px;
  font-size: 1.2em;
}

#result-query-preview {
  max-height: 300px;
  background-color: #d0d0d0;
  border-radius: 10px;
  height: 200px;
  padding: 11px
}

.printable-map {
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.logo-company img{
  height: 80px !important;
  width: auto !important;
}

.loading .loading-fullscreen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  /* background-color: rgba(0, 0, 0, 0.05); */
  transition: all 0.3s ease;
  opacity: 1;
  align-content: space-around;
  align-items: center;
  width: 99%;
  height: 100%;
  background-color: #ffffff !important;
  justify-content: center;
}

.pointerPosition {
  position: relative;
  left: 1px;
  top: 1px;
  font-size: 1.1rem;
  color: #333;
  font-weight: 400;
}

.image-item {
  width: 150px !important;
  margin: 15px;
  float: left;
}

#loader-title {
  font-size: 1.3em;
  font-weight: 600;
  padding: 8px 21px;
  text-align: left;
  border-bottom: 1px dotted #d0d0d0;
}

#loader-text {
  font-size: 1.2em;
  padding: 8px 21px;
}

#loader {
  bottom: 72px;
  left: 50%;
  position: fixed;
  box-sizing: border-box;
  grid-template-columns: minmax(0, 100%);
  width: 32em;
  max-width: 100%;
  /* padding: 0 0 1.25em; */
  border: none;
  border-radius: 5px;
  background: #fff;
  color: #545454;
  font-family: inherit;
  z-index: 999999999;
  width: 225px;
  /* height: 100px; */
  text-align: center;
  /* flex: auto; */
  /* font-size: 1.675em; */
  font-weight: 600;
}

#kt_aside_nav_tab_explorer {
  padding-left: '100px';
  width: '45rem';
  background-color: '#fff';
  box-shadow: '0 1px 2px rgba(60,64,67,0.3), 0 2px 6px 2px rgba(60,64,67,0.15)';
  z-index: 9;
  min-width: '36rem'
}

#botao-atalho {
  display: none;
}

@media (max-width: 990px) {
  #kt_aside_nav_tab_explorer {
    padding-left: 0px !important;
  }

}

@media (max-width: 890px) {
  #kt_aside_nav_tab_explorer {
    padding-left: 0px !important;
    position: fixed;
    display: none !important;
    top: 66px;
    left: 0px;
    z-index: 10;
    width: 29.3rem !important;
  }

  #botao-atalho {
    display: block;
  }
  #menu-topo-filtro {
    overflow: scroll;
    text-wrap: nowrap;
  }
  #btn-close-layer-explorer{
    display: block !important
  }

  #kt_aside_nav_tab_explorer.showMenu{
    display: block !important;
  }
}

.showMenu{
  display: block !important;
}

.logo {
  width: 60px;
  height: auto !important;
}

.logo-topo {
  width: auto !important;
  height: 30px;
}

/* 
#urbit-map div > img {
  position: absolute !important;
} */
/* img {
    max-width: auto !important;
    border:1px solid #d0d0d0;
  }
 */

@media print {

  .gm-style .gmnoprint,
  .gmnoprint {
    display: none;
  }
}

@media screen {

  .gm-style .gmnoscreen,
  .gmnoscreen {
    display: none;
  }
}

.table .active {
  background-color: #aee9ae;
}

.ficha-header {
  /* background-color: #e9e9e9; */
  color: #fff !important;
  box-shadow: 1px 2px 5px 0px #e7e7e7 !important;
}

.bg-grey {
  background-color: #d0d0d0;
}

.dNone {
  display: none;
}

.activeStep {
  display: block !important;
}

.clickPoint {
  cursor: pointer;
}

.containerWrapper {
  width: 100% !important;
  padding: 0px 40px;
}

.boxContent {
  padding: 1rem 3rem 6rem 3rem;
}

.fts-1 {
  font-size: 1rem !important;
}

.fts-1-2 {
  font-size: 1.2rem !important;
}

.fts-1-5 {
  font-size: 1.5rem !important;
}

.fts-2 {
  font-size: 2rem !important;
}

.fts-2-5 {
  font-size: 2.5rem !important;
}

.fts-3 {
  font-size: 3rem !important;
}

.fts-4 {
  font-size: 4rem !important;
}

.fts-5 {
  font-size: 5rem !important;
}

.mr-1 {
  margin: 1rem !important;
}

.mr-2 {
  margin: 2rem !important;
}

.mr-3 {
  margin: 3rem !important;
}

.mr-4 {
  margin: 4rem !important;
}

.mr-5 {
  margin: 5rem !important;
}

.mrt-1 {
  margin-top: 1rem !important;
}

.mrt-2 {
  margin-top: 2rem !important;
}

.mrt-3 {
  margin-top: 3rem !important;
}

.mrt-4 {
  margin-top: 4rem !important;
}

.mrt-5 {
  margin-top: 5rem !important;
}

.mrl-1 {
  margin-left: 1rem !important;
}

.mrl-2 {
  margin-left: 2rem !important;
}

.mrl-3 {
  margin-left: 3rem !important;
}

.mrl-4 {
  margin-left: 4rem !important;
}

.mrl-5 {
  margin-left: 5rem !important;
}

.mrb-1 {
  margin-bottom: 1rem !important;
}

.mrb-2 {
  margin-bottom: 2rem !important;
}

.mrb-3 {
  margin-bottom: 3rem !important;
}

.mrb-4 {
  margin-bottom: 4rem !important;
}

.mrb-5 {
  margin-bottom: 5rem !important;
}

.pd-left-1 {
  padding-left: 0.25rem !important;
}

.pd-left-2 {
  padding-left: 0.5rem !important;
}

.pd-left-3 {
  padding-left: 0.75rem !important;
}

.pd-left-4 {
  padding-left: 1rem !important;
}

.pd-right-1 {
  padding-right: 0.25rem !important;
}

.pd-right-2 {
  padding-right: 0.5rem !important;
}

.pd-right-3 {
  padding-right: 0.75rem !important;
}

.pd-right-4 {
  padding-right: 1rem !important;
}

.all-center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.all-center-start {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
}

.all-center-column {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
}

.all-start-center-column {
  display: flex !important;
  align-items: flex-start !important;
  justify-content: center !important;
  flex-direction: column !important;
}


.all-center-start-column {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  flex-direction: column !important;
}

.all-start-between-column {
  display: flex !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  flex-direction: column !important;
}

.all-center-between-column {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  flex-direction: column !important;
}

.all-center-end-column {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
  flex-direction: column !important;
}

.all-start-column {
  display: flex !important;
  justify-content: flex-start !important;
  flex-direction: column !important;
}


.table_vertical {
  display: flex;
  justify-content: space-between !important;
  justify-content: center;
}

.table_vertical tbody {
  display: flex;
  flex-direction: row;
}

.table_vertical tr {
  display: flex;
  flex-direction: column;

}

.table_vertical tr th,
td {
  padding: 2px;
  border: 1px solid #d0d0d0
}

.table_vertical td {

  border: 1px solid #d0d0d0
}

.zIndex-99 {
  z-index: 99 !important;
}

/* .table_vertical     th:first-child{
  padding-left: 10px !important;
} */


.all-center-around-column {
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
  flex-direction: column !important;
}

.all-start {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
}

.all-end {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
}

.all-full-end {
  display: flex !important;
  align-items: flex-end !important;
  justify-content: flex-end !important;
}

.all-center-space {
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
}

.all-center-between {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.all-start-between {
  display: flex !important;
  align-items: flex-start !important;
  justify-content: space-between !important;
}


.all-center-warp {
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap;
  flex-direction: row;
}

.radius-1 {
  border-radius: 0.5rem;
}

.radius-2 {
  border-radius: 0.75rem;
}

.radius-3 {
  border-radius: 1rem;
}

.radius-4 {
  border-radius: 1.25rem;
}

.radius-5 {
  border-radius: 1.5rem;
}

.text-justify {
  text-align: justify !important;
}

/* Buttons Template */
.btnPrimary {
  border: none !important;
  background-color: #05e798 !important;
  color: #fff !important;
  font-weight: 500 !important;
  font-size: 1.2rem !important;
}

.btnPrimary:hover {
  background-color: #08faa5 !important;
  color: #fff !important;
}

.btnSecondary {
  border: none;
  background-color: #f5f8fa !important;
  color: #000;
  font-weight: 500 !important;
  font-size: 1.2rem !important;
}

.btnSecondary:hover {
  background-color: #d5d7d8 !important;
  color: #000;
}

.btnHeavy {
  border: none;
  background-color: #e4e6ef !important;
  color: #000 !important;
  font-weight: 500 !important;
  font-size: 1.2rem !important;
}

.btnHeavy:hover {
  background-color: #d8dae0 !important;
  color: #000 !important;
}

.btnSuccess {
  border: none;
  background-color: #50cd89 !important;
  color: #fff !important;
  font-weight: 500 !important;
  font-size: 1.2rem !important;
}

.btnSuccess:hover {
  background-color: #42aa71 !important;
  color: #fff !important;
}

.btnInfo {
  border: none;
  background-color: #009ef7 !important;
  color: #fff !important;
  font-weight: 500 !important;
  font-size: 1.2rem !important;
}

.btnInfo:hover {
  background-color: #0183ce !important;
  color: #fff !important;
}

.btnDanger {
  border: none;
  background-color: #fc185c !important;
  color: #fff !important;
  font-weight: 500 !important;
  font-size: 1.2rem !important;
}

.btnDanger:hover {
  background-color: #f35181 !important;
  color: #fff;
}

.bgSecondary {
  background-color: #d3d3d3;
}

.bgBody {
  background-color: #f5f8fa !important;
}

.btnEye {
  color: #0e8c43 !important;
}


.btnEye:hover {
  color: #fff !important;
}


thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: #d0d0d0;
  border-style: solid;
  border-width: 0 !important;
  vertical-align: baseline;
}

.card .card-form {
  padding: 2rem 1.25rem !important;
}

.form-control-dual-list input {
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #181c32;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #e4e6ef;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.475rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control-dual-list select {
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #181c32;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #e4e6ef;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.475rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control-dual-list button {
  border: 0;
  padding: calc(0.75rem + 1px) calc(1.5rem + 1px);
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-padding-y: 0.75rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1.1rem;
  --bs-btn-font-weight: 500;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #181c32;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.475rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.6;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  box-shadow: var(--bs-btn-box-shadow);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* .card .card-body { */
/* padding: 1rem 1.25rem !important; */
/* } */

/* .card .card-header {
  padding: 0 1.25rem !important;
} */

.buttonIcon {
  margin: 0.25rem;
  border: none !important;
  padding: 0 !important;
  background-color: transparent !important;
}

.buttonHeaderMap {
  border: none;
  background-color: transparent !important;
  color: #20a475;
  font-weight: 500 !important;
  font-size: 1.2rem !important;
}

.cardFree {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px 30px 15px 30px;
  margin-bottom: 1.5rem;
}

.buttonCharts {
  border: none !important;
  background-color: #20a475 !important;
}

.input-Group {
  width: 100%;
  background-color: #f5f8fa !important;
  border-radius: 15px !important;
  padding: 1rem 2rem 1rem 1rem;
}

.inputGroup {
  width: 100%;
  border: none;
  text-decoration: none !important;
  outline: none !important;
  color: #7e8299 !important;
  font-weight: 600;
  font-size: 1.1rem;
  margin-left: 0.3rem;
  background-color: #f5f8fa !important;
}

.inputGroup::placeholder {
  color: #7e8299 !important;
  font-weight: 600;
  font-size: 1.1rem;
}

.inputGroup:focus {
  border: none !important;
  box-shadow: none !important;
}

.selectGroup {
  width: 100%;
  padding: 0.5rem;
  border: none !important;
  border-radius: 5px;
  background-color: #f5f8fa !important;
  font-weight: 600;
  color: #7e8299 !important;
  outline: none !important;
}

.checkboxGroup {
  padding: 0.5rem;
  border-radius: 5px;
  background-color: #f5f8fa !important;
  font-weight: 600;
  color: #7e8299 !important;
  outline: none !important;
}

.btn-color-green-900.btn.btn-active-light:hover svg {
  /* background-color: #20a745 !important; */
  color: #1EA377 !important;
}

.hoverAdressWidget {
  color: #7e8299 !important;
  border-bottom: 1px solid #dee3f6 !important;
  padding-left: 10px !important;
  border-left: 0.25rem solid #fff !important;
  transition: 0.2s;
  cursor: pointer;
}

.hoverAdressWidget:hover {
  border-left: 0.25rem solid #20a475 !important;
}

.buttonPageMapInfo {
  border: none;
  border-radius: 10px;
  padding: 10px;
  background-color: #20a475;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  color: #fff !important;
}

.max-w-1200px {
  max-width: 1200px !important;
  margin: auto;
}

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

.pointer {
  cursor: pointer !important;
}

.radioContent {
  padding-left: 0.5rem !important;
  font-size: 1.3rem !important;
  font-weight: 500 !important;
}

.footer {
  background-color: transparent !important;
}

.content {
  padding: 0 !important;
}

.card .card-header .card-title {
  margin: 0 !important;
}

/* Data table */

.contentHeader {
  width: 100% !important;
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
}

.contentPessoa {
  max-height: 100%;
}

/* Buttons Pessoa */

.btnNewService {
  margin-right: 10px;
  border-radius: 3.5px;
  padding: 8px 30px !important;
  background-color: #50cd89;
  color: white !important;
  font-size: 1.1rem;

  display: flex;
  align-items: center;

  transition: 0.9s;
}

.btnNewService:hover {
  background-color: #84d29f;
  /* border: 1px solid #84d29f; */
  color: #fff !important;
}

.btnTabs {
  margin-left: 0.5rem;
  font-size: 1.3rem !important;
  z-index: 99 !important;
  font-weight: 500;
  padding: 10px 20px !important;
}

/* Explorer */

.nav-explorer {
  display: flex !important;
  width: 100% !important;
  justify-content: flex-start !important;
  /* border-bottom: 1px solid #e4eaec !important; */
}

.nav-explorer li button {
  margin-right: 1rem;
  padding: 1rem !important;
  color: #7e8299 !important;
  font-size: 1.3rem;
  font-weight: 400 !important;
  border: none !important;
  background-color: white !important;
}

.nav-explorer li button.active {
  font-weight: 600 !important;
  font-size: 26px;
  /* color: #20a745 !important;
  border-bottom: 2px solid #20a745 !important; */
}

/* 
.buttonIconExplorer{
  padding: 8px !important;
  background-color: #F5F8FA !important;
  margin-left: 5px !important;
} */

.navTabs {
  display: flex !important;
  width: 100% !important;
  justify-content: flex-start !important;
  border-bottom: 1px solid #e4eaec !important;
}

.navTabs li button {
  margin-right: 1rem;
  padding: 1rem !important;
  color: #7e8299 !important;
  font-size: 1.3rem;
  font-weight: 400 !important;
  border: none !important;
  background-color: white !important;
}

.navTabs li button.active {
  font-weight: 600 !important;
  color: #20a745 !important;
  border-bottom: 2px solid #20a745 !important;
}

.nav-map-info {
  display: flex !important;
  width: 100% !important;
  justify-content: flex-start !important;
  border-bottom: 1px solid #e4eaec !important;
}

.nav-map-info li {
  margin-left: 2rem;
}

.nav-map-info li button {
  padding: 1rem !important;
  color: #7e8299 !important;
  font-size: 1.3rem;
  font-weight: 400 !important;
  border: none !important;
  background-color: white !important;
}

.nav-map-info li button:hover {
  border-bottom: 3px solid #7e8299 !important;
}

.nav-map-info li button.active {
  padding: 1rem !important;
  font-weight: 400 !important;
  color: #65e761 !important;
  border-bottom: 3px solid #4fce8a !important;
  z-index: 99;
}


.nav-map-analise {
  display: flex !important;
  width: 100% !important;
  border-bottom: 1px solid #e4eaec !important;
}


.nav-map-analise li button {
  padding: 1rem !important;
  color: #333 !important;
  font-size: 1.3rem;
  font-weight: 400 !important;
  border: none !important;
  background-color: #fff !important;
  border-radius: 5px 5px 0px 0px;
}

.nav-map-analise li button:hover {
  box-shadow: inset 0 -3px 0 #7e8299 !important;
}

.nav-map-analise li button.active {
  border: none;
  padding: 1rem !important;
  font-weight: 400 !important;
  color: #69C05B !important;
  box-shadow: inset 0 -3px 0 #69C05B !important;
  z-index: 99;
}


/* .cardMap{
  padding: 5px;
  border-radius: 10px;
  border:1px solid #d2d6ea;
} */

.borderInput {
  border: none !important;
  border-bottom: 2px solid #b5b5c3 !important;
  outline: none !important;
}

.cardIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 160px;
  text-align: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 8px;
}

.cardIcon p {
  font-weight: 600;
  margin: 0;
}

.cardIcon .textLight {
  font-weight: 300;
}

.CardShadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.bg-box {
  background-position: right top;
  background-repeat: no-repeat;
  background-size: 33% 37%;
  background-image: url('https://urbit.com.br/images/svg/shapes/abstract-3.svg');
  position: absolute;
  height: 170px;
  width: 30%;
}

.CardIndicesPainelMercado {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cardLote {
  background-color: #f5f5f5 !important;
  padding: 15px 30px !important;
  border-radius: 15px;
}

.btnNavigation {
  width: 100%;
  font-size: 16px !important;
  text-align: left;
  color: black !important;
  transition: 0.3ms;
  border-radius: 0px !important;
  margin: 3px 0px;
}

.btnNavigation:hover {
  background-color: #4fce8a !important;
  color: white !important;
}

.dropzone {
  cursor: pointer !important;

  border: 2px dotted #dcdcdc;
  border-radius: 15px;
}

.dropzone:focus {
  outline: none !important;
}

.upload-content {
  display: flex;
  align-items: center;
  /* Alinha os itens verticalmente */
  justify-content: center;
  /* Alinha os itens horizontalmente */
}

.upload-image-content {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  /* Transforma o elemento em um círculo */
  border: 2px solid #333;
  cursor: pointer;
  position: relative;
}

.upload-image-content:focus {
  border: 1px dotted #20a475 !important;
}

.upload-image-icon{
  position: absolute;
  bottom: -4px;
  background-color: #333;
  right: -5px;
  padding: 6px;
  border-radius: 50%;
}

.borderAccepted {
  border: 2px dotted #20a475 !important;
  width: 100%;
}

.borderRejected {
  width: 100%;
  border: 2px dotted #ff0000 !important;
}

#topo {
  background: linear-gradient(rgb(0 0 0 / 10%), rgb(0 0 0 / 30%)), url('../../img/bg-meio.png');
  /* background-image: url('../../img/bg-meio.png'); */
  background-size: cover;
  height: 100%;
  padding: 20px;
  color: #fff !important;
}

#menu-interno {
  color: #454f63;
}

#menu-interno #topo h4 {
  color: #fff !important;
  font-size: 1.1rem;
}

#menu-interno button {
  color: #454f63;
  width: 100%;
  padding: 15px;
  text-align: left;
  border-radius: 0;
}

#menu-interno button svg {
  float: left;
}

#menu-interno button span {
  padding-left: 5px;
  margin-top: 0px;
  display: block;
  float: left;
}

#menu-interno .nav-link.active {
  color: #0a700a !important;
  background: #f1f1f1 !important;
}

#menu-interno .nav-link:hover {
  color: #0a700a !important;
  background: #f1f1f1 !important;
}

#menu-interno .nav-item {
  margin: 0px !important;
  width: 100%;
}


.aside-explorer-public {
  height: 100%;
  padding-top: 100px;
  width: 40%;
  position: absolute;
  right: 0;
  top: 0;

  background: #eef5fa;
}

.aside-explorer-public .content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column
}

.aside-explorer-public .header-explorar-public {
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column
}

#expanded-aside-explorer-public {
  width: 100%;
}

@media (max-width: 990px) {
  .sm-justify-content-center {
    justify-content: center !important;
  }
}



.card-servico table {
  width: 100%;
  margin: 10px 0
}


.mydict div :focus {
  outline: 0;
  border-color: #2260ff;
  box-shadow: 0 0 0 4px #b5c9fc;
}

.mydict div {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;
  /* justify-content: center; */
}

.mydict input[type="checkbox"] {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.mydict input[type="checkbox"]:checked+span {
  box-shadow: 0 0 0 0.0625em #0043ed;
  background-color: #dee7ff;
  z-index: 1;
  color: #0043ed;
}

.mydict label span {
  display: block;
  cursor: pointer;
  background-color: #fff;
  padding: 0.575em 1.55em;
  position: relative;
  margin-left: .0625em;
  box-shadow: 0 0 0 0.0625em #b5bfd9;
  letter-spacing: .05em;
  color: #3e4963;
  text-align: center;
  transition: background-color .5s ease;
}

.mydict label:first-child span {
  border-radius: .375em 0 0 .375em;
}

.mydict label:last-child span {
  border-radius: 0 .375em .375em 0;
}

.label-center {
  display: flex;
  /* justify-content: flex-start; */
  align-items: center;
}

.overlay-full-page {
  width: 100% !important;
  height: 100%;
  background-color: #fafafa45 !important;
  left: 0 !important;
  bottom: 0 !important;

}

.overlay-full-page #loader-text {
  font-size: 1.2em;
  padding: 8px 21px;
  top: 50%;
  position: absolute;
  left: 50%;
  background-color: #fff;
}

.menu-info-emp-im {
  background-color: #000000;
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  overflow: overlay;
}

:root {
  --green: #0E8C43;
  --light-grey: #f0f0f0 ;
  --dark-grey: #555;
}


#toogle-tokens .btn:hover{
    color:#fff;
}
.radio-btn:hover {
  background-color:#23A775 !important;
    color: #ffffff !important;
}
.radio-btn:hover {
  background-color:#23A775 !important;
    color: #ffffff !important;
}

#list-check {
  font-weight: 300;
}
#list-check .form-check.form-check-solid .form-check-input:checked {
  background-color: #0e8c43 !important;
}
.radio-btn {
  appearance: none;
  display: none;
  height: 0;
  width: 0;
  
  & + label {
    display: inline-block;
    margin-bottom: .5em;
    padding: .75em 1.25em;
    border-radius: 100em;
    color: var(--dark-grey);
    background-color: var(--light-grey);
    font-weight: 300;
  }
  
  &:checked + label {
    background-color: var(--green);
    color: white;
  }

}
#mamapboxp {
  width: 75%;
  height: 100%;
}

.fakeLoader{
  position:absolute;
  top:50%;
  left:50%;
}

/* The popup bubble styling. */
.popup-bubble {
  /* Position the bubble centred-above its parent. */
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  /* Style the bubble. */
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  font-family: sans-serif;
  overflow-y: auto;
  max-height: 60px;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.5);
}

/* The parent of the bubble. A zero-height div at the top of the tip. */
.popup-bubble-anchor {
  /* Position the div a fixed distance above the tip. */
  position: absolute;
  width: 100%;
  bottom: 8px;
  left: 0;
}

/* This element draws the tip. */
/* .popup-bubble-anchor::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;

  transform: translate(-50%, 0);
 
  width: 0;
  height: 0;

  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid white;
} */

/* JavaScript will position this div at the bottom of the popup tip. */
.popup-container {
  cursor: auto;
  height: 0;
  position: absolute;
  /* The max width of the info window. */
  width: 200px;
}

.form-label {
  margin-bottom: auto  !important;
  /* font-size: 1.05rem; */

  padding-top: calc(0.75rem + 1px);
  padding-bottom: calc(0.75rem + 1px);
  margin-bottom: 0;
  font-size: inherit !important;
  font-weight: 500;
  line-height: 1.5;
  color: #3f4254  !important;
}

.temperatura {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
}
.temperatura div{
  /* width: 42px; */
  height: 32px;
  /* background-color: rgb(0, 0, 139); */
  border-radius: 8px 0px 0px 8px;
  color: white;
  display: flex;
  align-items: center;
  place-content: center;
  cursor: pointer;
  font-size: 14px;
  transition: 0.3s;
  border: none;
  padding: 15px;
}

.temperatura .active{
  border:2px solid #022953;
  height:44px
}
.temperatura span{
  color: #ffffff;
  text-shadow: 1px 1px #000000;
}

.badge-temperatura{
  /* width:35px; */
  color: #ffffff;
  text-shadow: 1px 1px #000000;
  margin: 0 auto;
  
}


.chat {
  width: 100%;
  height: 100%;
  /* display: none; */
  flex-direction: column;
  justify-content: space-between;
}

.chat__messages {
  flex-grow: 1;
  padding: 30px 30px 90px;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  overflow: scroll;
  max-height: 470px;

}

.chat__form {
  /* background-color: #191919; */
  padding: 15px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  /* position: fixed; */
  /* bottom: 0;
  left: 0; */
  width: 100%;
}

.chat__input {
  border: none;
  padding: 15px;
  border-radius: 8px;
  flex-grow: 1;
  background-color: #212121;
  outline: none;
  color: #f2f2f2;
  font-size: 1rem;
}

.chat__button {
  /* border: none;
  background: none; */
  color:rgb(0, 0, 0);
  cursor: pointer;
}

.chat__button > span {
  font-size: 1.8rem;
}

.chat__messages > div {
  padding: 10px;
  width: 100%;
  /* max-width: 250px; */
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 15px;
  line-height: 22px;
}

.message--self {
  background-color:#dedeff;
  color: #121212;
  border-radius: 10px 10px 0 10px;
  align-self: flex-end;
}

.message--other {
  background-color: #b1ffbf;
  color: #333;
  border-radius: 0 10px 10px 10px;
  align-self: flex-start;
}

.message--sender {
  display: block;
  margin-bottom: 15px;
  font-weight: 700;
  color: cadetblue;
}