.rstm-toggle-icon {
  display: inline-block; }
  .rstm-toggle-icon-symbol {
    width: 2rem;
    height: 2rem;
    text-align: center;
    line-height: 2rem; }


.border-dashed-default{
  border-bottom: 1px dashed rgb(90 90 90);
  border-top: 0px dashed rgb(208, 208, 208);
  /* padding: 5px 0px; */
}
#menu-layers{

  overflow: scroll;
  height: 100%;
  padding: 1px;
}
.rstm-tree-item label{
  display: block;
  padding-bottom: 3px;
  margin-top: 5px;
}
.rstm-tree-item.active  {
  /* color:#17d39f */
  color: #3b3b3b;
  font-weight: 400;
}
.rstm-tree-item.active i {
  /* color:#17d39f */
  color: #298111;
  font-weight: 400;
}

.rstm-tree-item.active .layer-name.collapsed{
  margin-bottom: 0px;
}
.rstm-tree-item.active .layer-name{
  /* margin-bottom: 10px; */
}


.rstm-tree-item-group {
  list-style-type: none;
  padding-left: 0;
  /* border-top: 1px solid #ccc; */
  text-align: left;
  width: 100%; 
  font-weight: 300;
}

.rstm-tree-item-group .active{
  border-radius: 6px;
}


.rstm-tree-item {
  padding: 0.65rem 0.1rem;
  cursor: pointer;
  color: #686868;
  background: none;
  /* border-bottom: 1px solid #ccc; */
  box-shadow: none;
  z-index: unset;
  position: relative; }
  .rstm-tree-item--active {
    color: white;
    background: #17d39f;
    /* background: #179ed3; */
    border-bottom: none; }
  .rstm-tree-item--focused {
    /* box-shadow: 0 0 5px 0 #222; */
    box-shadow: 0px 2px 2px 0px #d5d5d5;
    z-index: 999; }

.rstm-search {
  padding: 1rem 1.5rem;
  border: none;
  width: 100%; }



  .rstm-toggle-icon-action{
    display: inline-block;
    float: right ;

  }
  .rstm-toggle-icon-action a.nav-link{
    padding: 0;
    margin-top: 3px;
    color:#636363;
  }
.rstm-toggle-icon-action-eye{
  margin-top: 5px;
  display: inline-block;
  float: right ;

}
.rstm-toggle-icon-action-eye.active{
  background-color: transparent !important;
  margin-top: -5px;
}

.rstm-tree-item-group .active{
  background-color: #dcf3df;
  /* color:#fff */
  padding: 9px;
}
/* .position-map{
  bottom: 0px;
  position: absolute;
} */

h3{
  color:#6C757D !important
}

.menu-fixed{
  position: absolute !important;
  top: 10px;
  right: 70px;
  z-index: 999999;
}


.filtro-fixed {
  position: absolute !important;
  top: 62px;
  left: 11px;
  z-index: 999999;
  width: 28%;
  height: 84%;
  /* background-color: #fff; */
  /* border-radius: 6px;
  padding: 15px;
  box-shadow: 1px 2px 4px 2px #d0d0d0; */
}

.filtro-form {
  background-color: #fff;
  border-radius: 6px;
  padding: 15px;
  box-shadow: 1px 2px 4px 2px #d0d0d0;
}

.menu-lateral{
 width: 20%;
 background-color: #fff;

}

.filtro2-fixed{
  width: 30%;
  min-width: 415px;
  /* background-color: #fff; */
  /* height:88.5vh; */
  
}

.filtro2-form{
  padding:14px 40px 6px 0px;
  /* height: 83.4vh; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  overflow-x: hidden;
  }

.rc-slider-mark-text {
  left: auto  !important;
  transform: translateX(0%) !important;
}


.rc-slider-mark-text:nth-of-type(even) {
  left: auto !important ;
  right: 0px !important;
  transform: translateX(22%) !important;
}

.marker-label{
  margin-top: 50px;
  color:'#fff';
  font-weight: 500;
  background-color:#fff;
  padding: 5px;
}

#propertymap .gm-style-iw{
  box-shadow:none;
  color:#515151;
  font-family: "Georgia", "Open Sans", Sans-serif;
  text-align: center;
  width: 100% !important;
  border-radius: 0;
  left: 0 !important;
  top: 20px !important;
}

#propertymap .gm-style > div > div > div > div > div > div > div {
  background: none!important;
}

.gm-style > div > div > div > div > div > div > div:nth-child(2) {
   box-shadow: none!important;
}
#propertymap .gm-style-iw > div > div{
  background: #FFF!important;
}

#propertymap .gm-style-iw a{
  text-decoration: none;
}

#propertymap .gm-style-iw > div{
  width: 245px !important
}

#propertymap .gm-style-iw .img_wrapper {
  height: 150px;  
  overflow: hidden;
  width: 100%;
  text-align: center;
  margin: 0px auto;
}

#propertymap .gm-style-iw .img_wrapper > img {
  width: 100%;
  height:auto;
}

#propertymap .gm-style-iw .property_content_wrap {
  padding: 0px 20px;
}

#propertymap .gm-style-iw .property_title{
  min-height: auto;
}

.text-upper{
text-transform: uppercase;
}


.radio-inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 350px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-inputs > * {
  margin: 6px;
}

.radio-input:checked + .radio-tile {
  border-color: #2260ff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #2260ff;
}

.radio-input:checked + .radio-tile:before {
  transform: scale(1);
  opacity: 1;
  background-color: #2260ff;
  border-color: #2260ff;
}

.radio-input:checked + .radio-tile .radio-icon svg {
  fill: #2260ff;
}

.radio-input:checked + .radio-tile .radio-label {
  color: #2260ff;
}

.radio-input:focus + .radio-tile {
  border-color: #2260ff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px #b5c9fc;
}

.radio-input:focus + .radio-tile:before {
  transform: scale(1);
  opacity: 1;
}

.radio-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
  min-height: 80px;
  border-radius: 0.5rem;
  border: 2px solid #b5bfd9;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
}

.radio-tile:before {
  content: "";
  position: absolute;
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  border: 2px solid #b5bfd9;
  background-color: #fff;
  border-radius: 50%;
  top: 0.25rem;
  left: 0.25rem;
  opacity: 0;
  transform: scale(0);
  transition: 0.25s ease;
}

.radio-tile:hover {
  border-color: #2260ff;
}

.radio-tile:hover:before {
  transform: scale(1);
  opacity: 1;
}

.radio-icon svg {
  width: 2rem;
  height: 2rem;
  fill: #494949;
}

.radio-label {
  color: #707070;
  transition: 0.375s ease;
  text-align: center;
  font-size: 0.9rem;
}

.radio-input {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}



.task-single {
  position: relative;
  color: #2e2e2f;
  /* cursor: move; */
  background-color: #fff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
  margin-bottom: 1rem;
  border: 3px dashed transparent;
  max-width: 250px;
}

.legenda-left  .list {

  list-style: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 80px;
}

.legenda-left {
  background-color: #fff;
  border-radius: 3px;
  bottom: 25px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
  font: 12px / 20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  padding: 10px;
  position: absolute;
  left: 25px;
  z-index: 3;
  color: #333;
  width: 85px;
}
.legenda-left  .list li{

  list-style: none;
  flex-wrap: wrap;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;

}

.box-legenda{

  list-style: none;
  flex-wrap: wrap;
  padding: 5px;
  width: 15px;
  height: 15px;
  margin-right: 6px;
}

.task {
  /* font-size: 0.85rem; */
  position: relative;
  color: #2e2e2f;
  /* cursor: move; */
  background-color: #fff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
  margin-bottom: 1rem;
  border: 3px dashed transparent;
  max-width: 450px;
}

.task:hover {
  box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;
  border-color: rgba(162, 179, 207, 0.2) !important;
}

.task p {
  font-size: 15px;
  margin: 1.2rem 0;
}

.tag {
  border-radius: 100px;
  padding: 4px 13px;
  font-size: 12px;
  color: #ffffff;
  background-color: #1389eb;
}

.tags {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.options {
  background: transparent;
  border: 0;
  color: #c4cad3;
  font-size: 17px;
}

.options svg {
  /* fill: #9fa4aa; */
  width: 20px;
}

.stats {
  position: relative;
  width: 100%;
  color: #9fa4aa;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stats div {
  margin-right: 1rem;
  height: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.stats svg {
  margin-right: 5px;
  height: 100%;
  stroke: #9fa4aa;
}

.viewer span {
  height: 30px;
  width: 30px;
  background-color: rgb(28, 117, 219);
  margin-right: -10px;
  border-radius: 50%;
  border: 1px solid #fff;
  display: grid;
  align-items: center;
  text-align: center;
  font-weight: bold;
  color: #fff;
  padding: 2px;
}

.viewer span svg {
  stroke: #fff;
}


.stepper.stepper-links .stepper-nav {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}


.react-form-wizard .wizard-tab-content {
  min-height: 100px;
  padding: 30px 0px 0px;
  text-align: left; 
}

.siteBody {
  height: calc(100% - 164px);
  padding-top: 60px;
  display: flex;
  justify-content: flex-start;
  box-shadow: 0px 8px 14px -17px #111;
  width: 100%;
}

.sideBar {
  height: 100%;
  width: 400px;
  height: 100%;
}
.stepSummary {
  width: 80px;
  height: 100%;
}

.mainSection {
  height: 50%;
  width: calc(100% - 400px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999999;
}


.dados-position{
  position: relative;
  bottom: 10px;
}

.button-position{
  position: relative;
  bottom: 31px;
  background-color: #fff;
  padding: 13px;
}



#segmentacao .wrapper {
  display: flex;
  flex-direction: row;
  grid-gap: 30px;
  width: 100%;
  /* gap: 10px; */
  /* justify-content: center; */
  align-content: center;
  align-items: center;
  flex-wrap: nowrap;
  padding-left: 2px;
  padding-top: 20px;
  padding-bottom: 16px;
}

#segmentacao .card {
  position: relative;
  width: 100%;
  height: 260px;
  background: #fff;
  border-radius: 10px;
  transition: all 0.3s;
}

#segmentacao .card:hover {
  transform: scale(1.05);
}

#segmentacao .input {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  appearance: none;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  z-index: 10;
}

.group-input-segmentacao{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px !important;
  padding-bottom: 2px !important;

}

.input-segmentacao-area,.input-segmentacao-valor{
  text-decoration: none;
  outline: none;
  background: transparent;
  border: none;
  border-bottom: 2px solid #a1a5b7;
  min-width: 15px;
  max-width: 50px;
}

.input-segmentacao-area,.input-segmentacao-valor::focus{
  background: white;
  border-bottom: 2px solid red;
}

#segmentacao .input + .check::before {
  content: "";
  position: absolute;
  top: 15px;
  right: 15px;
  width: 16px;
  height: 16px;
  border: 2px solid #d0d0d0;
  border-radius: 50%;
  background-color: #E8E8E8;
}

#segmentacao .input:checked + .check::after {
  content: '';
  position: absolute;
  top: 17px;
  right: 17px;
  width: 12px;
  height: 12px;
  background-color: rgba(255,0,0,0.7);
  border-radius: 50%;
}

#segmentacao .input[value="standart"]:checked + .check::after {
  background-color: rgba(255,165,0,0.7);
}

#segmentacao .input[value="premium"]:checked + .check::after {
  background-color: rgba(0,128,0,0.7);
}

#segmentacao .input[value="basic"]:checked {
  border: 2.5px dashed rgba(255,0,0,0.7);
}

#segmentacao .input[value="standart"]:checked {
  border:2.5px dashed rgba(255,165,0,0.7);
}

#segmentacao .input[value="premium"]:checked {
  border: 2.5px dashed rgba(0,128,0,0.7);
}

#segmentacao h1 {

  padding: 30px 0px 0px 49px;

}

#segmentacao h2 {

  padding: 30px 0px 0px 49px;

}
#segmentacao h3 {

  padding: 0px 0px 5px 1px;

}
#segmentacao .label {
  color: #323232;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
}

#segmentacao .label .title {
  margin: 15px 0 0 15px;
  font-weight: 900;
  font-size: 15px;
  letter-spacing: 1.5px;
}

#segmentacao .label .price {
  margin: 0px;
  font-size: 20px;
  font-weight: 900;
}

#segmentacao .label .span {
  color: gray;
  font-weight: 700;
  font-size: 15px;
}

.box-performance {

  /* background-position: right top; */
    /* background-size: 30%; */
    /* background-image: url(/media/svg/shapes/abstract-4.svg); */
    box-shadow: 1px 2px 1px 1px #d0d0d0 !important;
    border: 1px solid #f3f3f3 !important;
}

.nav-im.nav-pills .nav-link{
border-radius: 0 !important;
}
.nav-im .nav-item{
margin: 0  !important;
}

.nav-im .nav-link{
  padding: 10px 15px;
  /* color:#525252 */
}


.busca-mapa{
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #d9d9d9c7;
}

.busca-mapa > div > input{
    width: 48% !important;
    height: 32px !important;
    padding: 25px !important;
    font-size: 17px !important;
    left: auto !important;
    top: 39% !important;
    margin:0 34% !important;
}

.filtro-disabled{
  position: relative;
  background-color: #ff0000;
  width: 100%;
  height: 100%;
}
.select-data-table{
  background-color: #00ff111a;
}
/* 
.loader:empty {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */

.arrow_box{
  padding: 5px;
}