#menu .list-grupo ul{
    display: none;
}
.list-grupo-active{
    display: block!important;
}
.list-grupo{
    float: left;
}
.list-grupo-item{
    float: left;
    display: none;
    
    padding-left: 0;

   

}
.border-bottom-tr{
    border-bottom: 1px dashed #d9d9d9 !important
}
.list-grupo-item a{
    margin:  2px !important;
}
#list-content-tags{
    width: 100%;
    /* background-color: #f5f8fa; */
    border-radius: 8px;
    display: flex;
    box-shadow: 0px 0px 5px 1px #d0d0d0;
    padding: 12px;
}
/* #menu .list-grupo:hover ul{
    display: block;
} */

.nav-item{
    float: left;
}

.box-color-gray{
    background-color: #f3f3f3 ;
    /* box-shadow: 1px 1px 1px 1px #d0d0d0; */
    box-shadow: 2px 1px 6px 1px #d0d0d0 !important;
  }
  .box-color-gray:hover{
    cursor:pointer;
    background-color: #f3f3f3 !important;
  }
  .list-grupo {
    float:left
  }