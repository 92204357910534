.aside-fixed .aside {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 98;
  /* box-shadow: 0 0 80px 50px rgb(206 213 225 / 12%); */
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(60,64,67,0.3), 0 2px 6px 2px rgba(60,64,67,0.15);
}

.aside .aside-primary {
  width: 100px;
}
.aside-primary{
  z-index:9 ;

}

.sub-menu-secondary{
  border-left: 3px solid #3e3e3e !important;
    border-radius: 0 !important;
}

/* .sub-menu-secondary:hover{
  border-left: 3px solid #0e8c43 !important;
    border-radius: 0 !important;
} */


.aside-secondary{
  /* margin-left: -100px; */
  z-index: 0;
  border-left: 1px solid #d0d0d0; 
}
.aside .aside-secondary .aside-workspace {
  width: 345px !important;
  flex-shrink: 0;
}

.seta{
    border-right: 15px solid #7e8299;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    float: left;
    margin-left: -15px;
}
/* 
.ContentAside{
  height: 150px;
  position: fixed;
  margin-left: 60px;
  display: none;
  z-index: 99999;
  
  background-color: #FFF;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 300px !important;
  border-radius:15px;
}

.hoverContent{
  display: flex;
  align-items: center;
}

.hoverContent:hover .ContentAside{
  display: block;
  align-items: center;
} */



#menu-principal{

}

#menu-principal li li {  
  /* list-style: none;   */
 
}  

.btn.btn-icon{
  height: calc(1.5em + 2.5rem + 2px) !important;
  width: calc(1.5em + 2.5rem + 2px) !important;
}
#menu-principal svg{
  height: 2.5em !important;
}

.aside-nav{
  width: 100px !important;
}
#menu-principal li { margin: 0; padding: 0; list-style: none; position:static; 
  height: 40px;
 }

#menu-principal  li ul  {  

  position: relative;
  display: none;
  /* top: -56px; */
  left: 165px;
  padding: 12px;

}  
/* .aside-secondary {

} */
.show-menu{
  display:block !important
}

.hide-menu{
  display:none !important
}
#menu-principal li:hover ul {  
  display: block;
}  
.dropdown-item.active, .dropdown-item:active{
    background-color: #f5f5f5 !important;
}
.dropdown-menu>li>a:hover, .dropdown-menu>li>a:focus {

}
.dropdown-menu>li>a:hover, .dropdown-menu>li>a:focus {
  text-decoration: none;
  color: #262626;

}

.dropdown-item{
  padding: 0.25rem 0rem;
}
.dropdown-menu>li>a {
  display: block;
  padding: 10px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.title-submenu .title-submenu-icone svg{
  display: block;
  float: left;
  width: 38px;
  font-size: 15px;
  font-weight: 500;
}
.title-submenu .title-submenu-texto{
  display: block;
  /* padding-top: 20px; */
  width: 100%;
  font-weight: 500;
  font-size: 15px;
}

.dropdown-item.active, .dropdown-item:active{
  background-color: #f5f5f5;
}