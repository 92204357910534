// Buttons.scss

// Definição de variáveis para cores
$gray-heavy: #7B7B7A;
$primary: #69C05B;
$secondary: #0F997D;
$black: #000000;
$gray-light: #D9DCDD;
$gray-blue: #f5f8fa;
$danger: #B02A37;
$info: #087990;

// Definição de mixin para gradientes horizontais
@mixin horizontal-gradient($color1, $percentage1, $color2, $percentage2) {
  background: linear-gradient(to right, $color1 $percentage1, $color2 $percentage2);
}

@mixin vertical-gradient($color1, $percentage1, $color2, $percentage2) {
  background: linear-gradient(to bottom, $color1 $percentage1, $color2 $percentage2);
}


// Tamanhos personalizados de botão
$button-size-lg: 16px 24px 16px 24px;
$button-size-md: 10px 16px 10px 16px;
$button-size-sm: 8px 12px 8px 12px;

// Mixin para aplicar tamanhos personalizados de botão
@mixin button-size($size) {
  padding: nth($size, 1) nth($size, 2) nth($size, 3) nth($size, 4);
}

// Estilo base do botão
.button {
  border: none;
  border-radius: 6px;
  gap: 8px;
  color: white;
  cursor: pointer;

  // Efeito hover com 90% de escurecimento
  &:hover {
    filter: brightness(90%);
  }
}

// Estilos para botão com background cinza pesado
.button-gray-heavy {
  @extend .button;
  background-color: $gray-heavy;
}

// Estilos para botão com background primário
.button-primary {
  @extend .button;
  background-color: $primary;
}

// Estilos para botão com background secundário
.button-secondary {
  @extend .button;
  background-color: $secondary;
}

// Estilos para botão com background preto
.button-black {
  @extend .button;
  background-color: $black;
}

// Estilos para botão com background cinza claro
.button-gray-light {
  @extend .button;
  background-color: $gray-light;
}

// Estilos para botão com background cinza azulado
.button-gray-blue {
  @extend .button;
  background-color: $gray-blue;
}

// Estilos para botão com background perigoso (danger)
.button-danger {
  @extend .button;
  background-color: $danger;
}

// Estilos para botão com background de informação (info)
.button-info {
  @extend .button;
  background-color: $info;
}

// Estilos para botão com gradiente misto primário
.button-horizontal-gradient {
  @extend .button;
  @include horizontal-gradient($secondary, 0%, $primary, 100%);
}

// Estilos para botão com gradiente misto secundário
.button-vertical-gradient {
  @extend .button;
  @include vertical-gradient($secondary, 0%, $primary, 100%);
}

// Estilos para tamanhos de botão
.btn-lg {
  @include button-size($button-size-lg);
}

.btn-md {
  @include button-size($button-size-md);
}

.btn-sm {
  @include button-size($button-size-sm);
}
