//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//i


// Theme colors
// Primary
$primary: if(isDarkMode(), #009ef7, #004DE5); 
$primary-active: if(isDarkMode(), #0095e8, #003CB2);
$primary-light: if(isDarkMode(), #212e48, #E0EBFF);
$primary-inverse: #ffffff;

// $primary: if(isDarkMode(), #009ef7, #0E8C43); 
// $primary-active: if(isDarkMode(), #0095e8, #0B7337);
// $primary-light: if(isDarkMode(), #212e48, #DAF2E4);
// $primary-inverse: #ffffff;


$secondary: if(isDarkMode(), #990f0f, #F1F1F2);
$secondary-active: if(isDarkMode(), #48990f, #d2d2d2);
$secondary-light: if(isDarkMode(), #212e48, #DAF2E4);
$secondary-inverse: #777777;


// Theme colors
// default
$default: if(isDarkMode(), #e2e2e2, #e2e2e2);
$default-active: if(isDarkMode(), #187de4, #0095e8);
$default-light: if(isDarkMode(), #212e48, #f1faff);
$default-inverse: #ffffff;


// Success
$success: if(isDarkMode(), #50cd89, #0E8C43);
$success-active: if(isDarkMode(), #47be7d, #0B7337);
$success-light: if(isDarkMode(), #1c3238, #DAF2E4);
$success-inverse: #ffffff;

// Info
$info: if(isDarkMode(), #317ca5, #317ca5);
$info-active: if(isDarkMode(), #38789b, #38789b);
$info-light: if(isDarkMode(), #2f264f, #f8f5ff);
$info-inverse: #ffffff;

// Danger
$danger: if(isDarkMode(), #f64e60, #f1416c);
$danger-active: if(isDarkMode(), #ee2d41, #d9214e);
$danger-light: if(isDarkMode(), #3a2434, #fff5f8);
$danger-inverse: #ffffff;

// Warning
$warning: if(isDarkMode(), #ffa800, #ffc700);
$warning-active: if(isDarkMode(), #ee9d01, #f1bc00);
$warning-light: if(isDarkMode(), #392f28, #fff8dd);
$warning-inverse: #ffffff;
