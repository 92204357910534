.filter-active{
  min-height: 350px !important;
  flex-direction: column !important;
}

.card-form {
  background-color: #fff;
  width: 100% !important;
  /* z-index: 99; */
  position: relative !important;
}

#acc{
  display: none;
  opacity: 0;
  transform: translateY(-15px);
}

#acc.show{
  display: block;
  animation-name: slide;
  animation-duration: 0.7s;
  animation-fill-mode: forwards;
}

@keyframes slide {

  to {
    transform: translateY(0);
    opacity: 1;
  }
}
