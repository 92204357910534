.gallery{
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;
}

.tab-report.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  background-color: #ececec;
  color: #454545;
  border-radius: 10px;
}

/* #f1f1f1 */


.tab-report ul li{

  padding: 10px;
}
.tab-report.nav-link{
  color:#181c32 !important

}
.tab-report.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus{
  color: rgb(42, 81, 28);
  background-color: #f6f6f6;
  border-radius: 10px;
}
.tab-report{
  padding: 10px;

  /* display: flex; */
  width: 100%;
  background: #fff;
  /* align-items: stretch; */
  font-size: 15px;
  color:#181c32;
  /* font:12pt "Inter, Poppins, Helvetica" */
  
}



.bg-grey{
  background-color: #f1f1f1
}
.zoom {
  background-color: green;
  transition: transform .2s;
  margin: 5px 5px;
  dsiplay: flex;
  /* width: 300px; */
  height: 217px;
  flex-wrap: wrap;
  transition: 0.3s all;
  }
  
  .zoom:hover {
    border-radius: 25px;
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* Safari 3-8 */
    transform: scale(1.1); 
  }