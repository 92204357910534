body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #FAFAFA;
    font: 12pt "Tahoma";
}
* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}
.page-init{
    display: flow;
    margin: 0 auto;
}
.page-relatorio {
    width: 210mm;
    min-height: 297mm;
    padding: 10mm;
    margin: 10mm auto;
    border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.page-relatorio .subpage {
    /* padding: 1cm; */
    border: 1px rgb(255, 255, 255) solid;
    /* height: 257mm; */
    /* outline: 2cm #FFEAEA solid; */
}
.ficha-header{
    font-size: 1.30rem !important;
    background-color: #e9e9e9;
    color: #202124 !important;
    box-shadow: 0px 0px 0px 0px #e7e7e7 !important;
    border-bottom: 1px dashed #f3f3f3 !important;
    border-radius: 0 !important;
}
@page {
    size: A4;
    margin: 0;
}
@media print {
    html, body {
        width: 210mm;
        height: 297mm;        
    }
    .page-relatorio {
        margin: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
        background-color: #fff;
    }

    .no-print, .no-print *
    {
        display: none !important;
    }
}

.shadow-clean{
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px  !important
    
}