.suggestions{
  background-color: #fff;
}

.box1{
  padding: 10px;
  display: block;
  flex-direction: collumn;
  justify-content: center;
  align-items: center;
}

.box1 h1{
  padding: 0;
}

.box1 p{
  padding: 0;
}

.box2{

}