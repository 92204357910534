.DateRangePickerInput__withBorder {
  display: flex !important;
    width: 100% !important;
    justify-content: space-between !important;
    flex-wrap: wrap !important;
}

.DateRangePicker {
  position: relative;
  display: block !important;
}

.DateRangePickerInput_arrow{
  display: none !important; 
}

.DateInput_input {
  font-weight: 200;
  font-size: 19px;
  line-height: 24px;
  background-color: #f5f8fa !important;
  border-color: #f5f8fa !important;
  color: #5e6278 !important;
  width: 100% !important;
  padding: 11px 11px 9px;
  border: 0;
  border-bottom: 4px solid #69C05B !important;
}

.DateInput {
  width: 200px  !important;;
  max-width: 200px !important;
}
.DateRangePickerInput__withBorder {
  border: none !important;
}

.CalendarDay__selected:hover {
  color: white;
}

.CalendarDay__selected_span{
  border-radius: 0px !important;
  box-sizing: border-box !important;
  cursor: pointer !important;
  text-align: center !important;
  color: rgb(34, 34, 34) !important;
  padding: 0px !important;
  background: #f2f5f5 !important;
}

.CalendarDay__selected_span:active, .CalendarDay__selected_span:hover{
  background: #e4e7e7 !important;
}

.CalendarDay__selected {
  background: #69C05B !important;
  border-radius: 50% !important;
  transition: 0.2s all;
}


.CalendarDay__selected:active{
  background: red !important;
}

.CalendarDay__selected:hover{
  background: #5bab4f !important;
}

.CalendarDay__default:hover {
  background: #5dab50 !important;
  color: white !important;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover{
  background: #e4e7e7 !important;
  color: rgb(34, 34, 34) !important;
}

.CalendarDay {
  border-radius: 50% !important;
  text-align: center;
  vertical-align: middle;
  text-align: center;
  gap: 5px;
}

